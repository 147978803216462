import React from "react"
import PropTypes from "prop-types"
import ABOMS from "./logo-ABOMS.svg"
import SocialReviews from "../../components/SocialReviews/SocialReviews"

function ContentColumn({ heading, topBlurb, language, badge, doctor }) {
  let docName = heading
  docName = addNewlineAfterString(docName, "Meet")

  return (
    <div className="column is-10 doctor-top-intro">
      <div
        style={{ whiteSpace: "pre-wrap" }}
        className="doctor-top-intro-inner">
        <h1>{docName}</h1>
        <h3 className="large-doctor-text large">{topBlurb}</h3>
        <div className="rating-review columns is-centered is-vcentered">
          <div className="column is-half">
            <SocialReviews
              socialData={doctor}
              google
              isCentered
              language={language}
            />
          </div>
          {badge && badge.hasBadge && (
            <>
              <div className="column is-3" />
              <div className="column is-6">
                <img className="badge" src={ABOMS} />
              </div>
              <div className="column is-3" />
            </>
          )}
        </div>
      </div>
    </div>
  )
}

ContentColumn.propTypes = {
  heading: PropTypes.string.isRequired,
  topBlurb: PropTypes.string.isRequired,
  language: PropTypes.string,
  badge: PropTypes.object,
  hasBadge: PropTypes.bool
}

export default ContentColumn

function addNewlineAfterString(/* in/out */ text, /* in */ str) {
  return text.replace(`${str} `, `${str}\n`)
}
